import conf from './http_config'
import utils from '../../utils'
import * as _ from 'lodash'
//@ts-ignore
import fetchRetry from 'fetch-retry'
import BasicModule from '../../basic-module';

// Initialize fetch-retry with the browser native fetch
let fetchRetryInstance: ReturnType<typeof fetchRetry>;
if (window.fetch) {
	fetchRetryInstance = fetchRetry(window.fetch)
} else if (global.fetch) {
	// react-native and other node-like envs
	fetchRetryInstance = fetchRetry(global.fetch)
} else {
	// For test environment
	// @ts-ignore
	fetchRetryInstance = (arg_a, arg_b) => console.log("fetch ", arg_a, arg_b)
}
 

let instance = null

/**
 * Http module manage the http/https network requests for the SDK.
 * @class Http
 * @private
 * @param {Object} config - Configurations object.
 * @prop  {Array} methods - Expose the methods the module support.
 */
class Http extends BasicModule implements Initiable<Http> {
	loaded: boolean
	config: any
	methods: ['get', 'post', 'delete']

	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.http, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.http] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @returns Module is ready.
	 */
	init(config = {}, defaults = conf.defaults.http, props = conf.configProps) {
		const retryConfig = {
			retries: 3, // if call fails or have 500 http response code then it will retry 3 times max untill success
			retryDelay: function(attempt, error, response) { // how much miliseconds wait to call next retry request
				return Math.pow(2, attempt) * 1000;
			},
			retryOn: [500] // add specific http status code which should be considered for retry
		}
		const concatConfig = Object.assign({}, defaults, config)
		this.config = _.pick(concatConfig, props)
		this.methods = ['get', 'post', 'delete'] // List of supported methods in the module.
		this.methods.forEach(method => _.assign(this.config[method], retryConfig))
		this.loaded = true
		return this
	}

	/**
	 * Fetching data on http protocol using GET method.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {String} url - Destination url.
	 * @param {Object} [params = {}] - Request params.
	 * @param {Object} [config] - Request config.
	 * @param {Function} [fetchApi = fetch]- Fetch method.
	 * @returns {Promise<Response>} Server response.
	 */
	get(url, params = {}, config: any = {}, fetchApi = fetchRetryInstance) {
		utils.validateDependencies([
			{name: 'url', type: 'String', val: url},
			{name: 'params', type: 'Object', val: params},
			{name: 'config', type: 'Object', val: config},
			{name: 'fetchApi', type: 'Function', val: fetchApi},
		])

		const stringifyParams = this.serializeForm(params).toString()
		const fetchConfig = {
			...this.config.get,
			...config,
			headers: {
				...(this.config.get.headers || {}),
				...(config.headers || {})
			}
		}

		return fetchApi(`${url}?${stringifyParams}`, fetchConfig)
	}

	/**
	 * Fetching data on http protocol using POST method.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {String} url - Destination url.
	 * @param {Object} [params = {}] - Request params.
	 * @param {Object} [config] - Request config.
	 * @param {Function} fetchApi=fetch - Fetch method.
	 * @returns {Promise<Response>} Server response.
	 */
	post(url, params = {}, config: any = {}, fetchApi = fetchRetryInstance) {
			utils.validateDependencies([
				{name: 'url', type: 'String', val: url},
				{name: 'params', type: 'Object', val: params},
				{name: 'config', type: 'Object', val: config},
				{name: 'fetchApi', type: 'Function', val: fetchApi},
			])

			const fetchConfig = {
				method: 'POST',
				mode: 'cors',
				body: JSON.stringify(params),
				...(this.config.post || {}),
				...(config || {}),
				headers: {
					...(this.config.post.headers || {}),
					...(config.headers || {})
				}
			}

			return fetchApi(`${url}`, fetchConfig)
	}

	/**
	 * Sending data on http protocol using DELETE method.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {String} url - Destination url.
	 * @param {Object}[params = {}] - Request params.
	 * @param {Object} [config] - Request config.
	 * @param {Function} fetchApi=fetch - Fetch method.
	 * @returns {Promise<Response>} Server response.
	 */
	delete(url, params = {}, config: any = {}, fetchApi = fetchRetryInstance) {
			utils.validateDependencies([
				{name: 'url', type: 'String', val: url},
				{name: 'params', type: 'Object', val: params},
				{name: 'config', type: 'Object', val: config},
				{name: 'fetchApi', type: 'Function', val: fetchApi},
			])

			const fetchConfig = {
				method: 'DELETE',
				mode: 'cors',
				body: JSON.stringify(params),
				...(this.config.delete || {}),
				...(config || {}),
				headers: {
					...(this.config.delete.headers || {}),
					...(config.headers || {})
				}
			}

			return fetchApi(`${url}`, fetchConfig)
	}

	/**
	 * Convert POJO to form in x-www-form format.
	 * @version 1.0.0
	 * @private
	 * @param {Object} form - Form object.
	 * @param {String} [objName = ''] - Property name.
	 * @param {URLSearchParams} pastData - Legacy URLSearchParams from last recursive cycle.
	 * @returns Serialize form in x-www-form format.
	 */
	serializeForm(form, objName = '', pastData?) {
		utils.validateDependencies([
			{name: 'form', type: 'Object', val: form},
			{name: 'objName', type: 'String', val: objName},
			{name: 'pastData', type: ['Object', 'Undefined'], val: pastData},
		])

		return Object.keys(form).reduce((data, key) => {
			if(pastData)
				data = pastData
			if(!_.isNull(form[key]) && !_.isUndefined(form[key])) {
				if(_.isObject(form[key])) {
					if(objName !== '')
						this.serializeForm(form[key], `${objName}[${key}]`, data)
					else
						this.serializeForm(form[key], key, data)
				} else {
					if(objName !== '')
						data.set(`${objName}[${key}]`, form[key])
					else
						data.set(key, form[key])
				}
			}
			return data
		}, new URLSearchParams())
	}
}

export default Http
