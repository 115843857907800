import conf from './inbox_config'
import Notification from '../notification'
import utils from '../../../utils'
import Sdk from '../../../sdk'
import * as _ from 'lodash'
import {Subject as Rxjs_subject} from 'rxjs'
import Communication from '../../../communication/communication'
import { get_mustache_context, render_template } from '../../../lib/render_template_string'

let instance = null // init the instance.
let inbox = null // init the full inbox observable.
let messages = null // init the messages observable.
let notifications = null // init the notifications observable.

/**
 * Inbox module manage the notification type inbox in the SDK, he responsible to
 * update the SDK model about new inbox messages the user get also some times points
 * get with inbox messages.
 * @category Notification
 */
class Inbox implements Initiable<Inbox> {
	loaded: boolean
	config: any
	/** Expose the inbox state. */
	state: Rxjs_subject<any>
	/** Expose the state of messages with messages type. */
	messages: Rxjs_subject<any>
	/** Expose the state of messages with notifications type. */
	notifications: Rxjs_subject<any>
	/** The last unread_inbox for the user fetched.
	 * It is saved here because this lives under the `user` object,
	 * which can be updated through activities websocket.
	 * And then, in the case of a message triggered by action,
	 * this will already update it. And we need the last state to
	 * know which messages are new - to push an update to the RX subjects accordingly.
	 */
	last_unread_inbox_state: any[]
	interval: ReturnType<typeof setInterval>


	/**
	 * Construct the module.
	 * @private
	 * @param {Object} [config] - Configurations object.
	 * @returns {Promise<Object>|object} Module instance.
	 */
	constructor(config = {}) {
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config, conf.defaults.inbox, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {Object} [config] - Configurations object.
	 * @param {Object} [defaults = conf.defaults.badges] - Defaults object.
	 * @param {Object} [props = conf.configProps] - Valid config properties array.
	 * @param {Object} [sdk = new Sdk] - Sdk module.
	 * @returns {Promise<boolean>} Module is ready.
	 */
	init(config: any = {}, defaults = conf.defaults.inbox, props = conf.configProps, sdk = new Sdk) {
		// Merge between defaults config and merged server+developer configs.
		const concatConfig = {}
		Object.assign(concatConfig, defaults, config)
		this.config = _.pick(concatConfig, props) // Exclude the invalid configuration
		// initialize rxjs subject as the module state.
		if (!inbox || !messages || !notifications || config.test) {
			inbox = new Rxjs_subject<any>()
			messages = new Rxjs_subject<any>()
			notifications = new Rxjs_subject<any>()
			this.state = inbox
			this.messages = messages
			this.notifications = notifications
		} else {
			this.state = inbox
			this.messages = messages
			this.notifications = notifications
		}

		sdk.isReady()
			.then(sdk => sdk.user.get())
			.then((player) => {
				if(!player.is_anonymous && this.config.inboxSync)
					this.interval = this.startSyncing() // Start syncing data from server
			})
			.then(() => this.loaded = true)

		this.listen_for_reset_data()
		return this
	}

	/**
	 * Start sync process to get inbox notifications from server.
	 * @version 1.0.0
	 * @private
	 * @param interval - Interval process id.
	 * @param intervalTime - Interval time per request.
	 * @returns {Number} Interval id.
	 */
	startSyncing(interval: ReturnType<typeof setInterval> = this.interval, intervalTime: number = this.config.intervalTimeInbox) {
		// Check if the interval and intervalTime is valid.
		utils.validateDependencies([
			{name: 'interval', type: ['Number', 'Undefined'], val: interval},
			{name: 'intervalTime', type: 'Number', val: intervalTime}
		])
		// Hard minimum limit
		intervalTime = Math.max(intervalTime, this.config.intervalTimeInbox)

		if (interval)
			this.stopSyncing(interval)

		this.get() // Fetch data immediately and then ->
		return setInterval(() => { // Fetch data every X(defaults: 60s) time.
			this.get()
		}, intervalTime)
	}

	/**
	 * Stop sync process to get inbox notifications from server.
	 * @version 1.0.0
	 * @private
	 * @param [interval = this.interval] - Interval process id.
	 */
	stopSyncing(interval: ReturnType<typeof setInterval> = this.interval) {
		// Check if the interval and intervalTime is valid.
		utils.validateDependencies([
			{name: 'interval', type: 'Number', val: interval}
		])

		return clearInterval(interval)
	}

	/**
	 * Get new `inbox` notifications and trigger an event to notify that new notifications
	 * is in @player.unread_inbox
	 * @version 1.0.0
	 * @async
	 * @public
	 * @param {String} type - Type of inbox item to expect, accept: `messages`, `notifications` or it will returns all types.
	 * @param {Object} [notification = new Notification] - Notification instance.
	 * @param user User config.
	 * @returns {Promise<Array<InboxItem>>} The updated unread_inbox messages.
	 * @example
	 * captain.inbox.get().then(unread_inbox => {
	 * 	// Player unread inbox messages.
	 * })
	 */
	async get(type?, notification = new Notification, user = (utils.get_environment_global_var())['captain'].player || (utils.get_environment_global_var())['captain'].config.player) {
		// Check if the notification, user and addPoints is valid.
		utils.validateDependencies([
			{name: 'type', type: ['String', 'Undefined'], val: type},
			{name: 'notification', type: 'Object', val: notification},
			{name: 'user', type: 'Object', val: user},
		])

		const updateState = (res = []) => {
			if (this.notifications_change(this.last_unread_inbox_state || [], res)) {
				const inboxItems = _.differenceBy(res, this.last_unread_inbox_state || [], '_id')
				// Render the templates on the response
				for (let inbox_message of res) {
					const mustache_context = get_mustache_context(user, 'message', {})
					inbox_message.entity.content = render_template(inbox_message.entity.content, mustache_context)
					inbox_message.entity.content_markdown = render_template(inbox_message.entity.content_markdown, mustache_context)
					inbox_message.entity.short_content = render_template(inbox_message.entity.short_content, mustache_context)
					inbox_message.entity.title = render_template(inbox_message.entity.title, mustache_context)
					inbox_message.entity.name = render_template(inbox_message.entity.name, mustache_context)
				}

				// Update the unread_inbox and this.last_unread_inbox_state
				user.unread_inbox = res
				this.last_unread_inbox_state = res
				if (_.isArray(user.unread_inbox) && user.unread_inbox.length > 0) {
					// Update the state with new inbox items.
					this.updateMessages(inboxItems)
					this.updateNotifications(inboxItems)
					this.state.next(res)
				}
				return res
			}
			return user.unread_inbox
		}

		const filterTypes = (type) => (inboxItems) => {
			if(type === 'messages')
				return inboxItems.filter((inboxItem) => !inboxItem.entity.notification)
			if(type === 'notifications')
				return inboxItems.filter((inboxItem) => inboxItem.entity.notification)
			return inboxItems
		}

		const notifications_data = await notification.get_notifications_data()
		const res = updateState(notifications_data)
		return filterTypes(type)(res)
	}

	/**
	 * Updates the state with new messages
	 * @version 1.0.0
	 * @private
	 * @param {Array<InboxItem>} [inboxItems = []] - List of new inbox items that player recently received.
	 */
	updateMessages(inboxItems = []) {
		const messages = inboxItems
			.filter((inboxItem) => !inboxItem.entity.notification)
		if (!_.isEmpty(messages))
			this.messages.next(messages)
	}

	/**
	 * Updates the state with new notifications
	 * @version 1.0.0
	 * @private
	 * @param {Array<InboxItem>} [inboxItems = []] - List of new inbox items that player recently received.
	 */
	updateNotifications(inboxItems = []) {
		const notifications = inboxItems
			.filter((inboxItem) => inboxItem.entity.notification)
		if (!_.isEmpty(notifications))
			this.notifications.next(notifications)
	}


	/**
	 * Validate the inbox notification who came from server are not already exist locally.
	 * @version 1.0.0
	 * @private
	 * @param {Array} [local = []] - Local inbox notifications.
	 * @param {Array} [server = []] - New inbox notification from server.
	 * @returns {Boolean} If the inbox notification who came from server are not exist locally.
	 */
	notifications_change(local = [], server = []) {
		// Check if the local and server is valid.
		utils.validateDependencies([
			{name: 'local', type: 'Array', val: local},
			{name: 'server', type: 'Array', val: server},
		])

		const current_notif_ids = local.map((val, key) => val._id)
		const server_notif_ids = server.map((val, key) => val._id)

		return (_.difference(current_notif_ids, server_notif_ids).length !== 0 || _.difference(server_notif_ids, current_notif_ids).length !== 0)
	}

	/**
	 * Subscriber for opening inbox events and trigger 'onReadInbox' method.
	 * @version 1.0.0
	 * @private
	 * @param on - Subscribe to events method.
	 */
	listen_for_reset_data(on = (utils.get_environment_global_var())['captain'].on || utils.on) {
		// Check if the on is valid.
		utils.validateDependencies([
			{name: 'on', type: 'Function', val: on},
		])

		on('activity_widget:open', this.onReadInbox.bind(this))
		return on('activity_widget:feed:toggle', this.onReadInbox.bind(this))
	}

	/**
	 * Callback for events of opening an inbox screen, check if data feed is inbox if so mark the inbox as read
	 * @version 1.0.0
	 * @private
	 * @param {Object} [event = {}] - Event data.
	 * @returns {Boolean|Promise<Array>} If the event data target for inbox.
	 */
	onReadInbox(event: any = {}) {
		// Check if the event is valid.
		utils.validateDependencies([
			{name: 'event', type: 'Object', val: event},
		])

		// Problem: This way of implementation can cause losing of notification because
		// we dont tell the server what was read only that we read so if multiple
		// notifications sent in close time we may lose some of them because after
		// the first we already tell the server that we read all the notifications
		if (event.feed === 'inbox')
			return this.markAsRead()
		return false
	}

	/**
	 * Notify the server of getting the inbox notification.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {String} messageId - Message inbox id.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param user - User config.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Array<InboxItem>} The updated unread_inbox messages.
	 * @example
	 * captain.inbox.markAsRead('*messageId*') // Mark specific inbox item as read
	 * .then(unread_inbox => {
	 * // Updated unread inbox
	 * })
	 * @example
	 * captain.inbox.markAsRead() // Mark all inbox items as read
	 * .then(unread_inbox => {
	 * // Updated unread inbox
	 * })
	 */
	markAsRead(messageId?, sdk = new Sdk, user = (utils.get_environment_global_var())['captain'].player || (utils.get_environment_global_var())['captain'].config.player, communication = new Communication) {
		// Check if the sdk, user, communication are valid.
		utils.validateDependencies([
			{name: 'messageId', type: ['String', 'Undefined'], val: messageId},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'user', type: 'Object', val: user},
			{name: 'communication', type: 'Object', val: communication},
		])

		if(user.is_anonymous)
			return Promise.reject(false)

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/unread_inbox/${sdk.config.player.id}`
		const params = {
			user_id: sdk.config.player.id,
			...(messageId) ? {message_id: messageId} : {}
		}
		const config = {
			requestType: 'http',
			method: 'post'
		} as const

		// Callback to notify the entire app that the notification inbox are clear now.
		const success = (res) => {
			user.unread_inbox = res.data
			this.state.next(user.unread_inbox)
			return res
		}

		return communication.request(url, params, config, success.bind(this))
			.then(success)
	}

	/**
	 * Get list of inbox messages.
	 * @version 1.0.0
	 * @public
	 * @async
	 * @param {Number} [limit = 5] - Limit the length of data to fetch.
	 * @param {Number} [skip = 0] - Skip numbers of rows for start(0).
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @param {Object} [communication = new Communication] - Communication instance.
	 * @returns {Promise<Object>} Server response for list of inbox messages.
	 * @example
	 * captain.inbox.getList()
	 * .then(inboxItems => {
	 * // 5 last inbox items
	 * })
	 * @example
	 * captain.inbox.getList(10, 5)
	 * .then(inboxItems => {
	 * // 10 next inbox items(skip first 5 items)
	 * })
	 */
	getList(limit = 5, skip = 0, sdk = new Sdk, communication = new Communication) {
		utils.validateDependencies([
			{name: 'limit', type: 'Number', val: limit},
			{name: 'skip', type: 'Number', val: skip},
			{name: 'sdk', type: 'Object', val: sdk},
			{name: 'communication', type: 'Object', val: communication},
		])

		const url = `${sdk.config.domain}/mechanics/${communication.config.api_version}/actions`
		const params = {
			limit,
			skip,
			player_id: sdk.config.player.id,
			app: sdk.config.id,
			feed_type: 'inbox'
		}
		const config = {
			requestType: 'http',
			method: 'get'
		} as const
		const cb = (x) => x
		return communication.request(url, params, config, cb)
	}
}

export default Inbox
