import * as _ from 'lodash'
import BasicModule from './basic-module'

// Generate random hash.
const get_hash = () => {
	return Math.random().toString(36).substring(7)
}

/**
 * @private
 * @returns is operating in a browser environment
 */
const is_browser = () => {
	if (webpackGlobal.__isNativeModule__)
		return false
	else
		return true
}

/**
 * @private
 * @returns the global var for the environment, 'window' for browser, 'global' otherwise
 */
const get_environment_global_var = () => {
	if (is_browser()) {
		return window
	} else if (typeof global !== 'undefined') {
		return global
	}
}

// Init module and resolve the module instance to the attach object.
function moduleInitializer<T extends BasicModule>(
	attachmentObj: Object, moduleName: string, Module: new(config) => T, config): T
	{
	const newModuleConfig = Object.assign({}, config)
	newModuleConfig.restart = true
	try {
		const instance = new Module(newModuleConfig)
		attachmentObj[moduleName] = instance
		return instance
	} catch (err) {
		console.error('Fatal Error:', err)
	}
}

const noop = () => {}

let eventStore
if (!webpackGlobal.__isNativeModule__) {
	eventStore = document.createElement('event')
}

const trigger = (name, o) => {
	if (eventStore) {
		const event = new CustomEvent(name, {detail: o})
		eventStore.dispatchEvent(event)
	}
}

const on = (name, cb) => {
	if (eventStore) {
		eventStore.addEventListener(name, (event) => cb(event.detail))
	} else {
		console.warn("Cant subscribe to events on your current platform. SDK events are supported on browsers only")
	}
}

type methodTypeValidation = 'Object' | 'Function' | 'String' | 'Number' | 'Array' | 'Undefined' | 'Boolean'
/**
 * Given validations settings, it throws errors if values does not confirm to validations.
 * @param validations 
 * @returns 
 */
const validateDependencies = (validations: {name: string, type: methodTypeValidation|methodTypeValidation[]|((val: any) => boolean), val: any}[] = []) => {
	const method = {
		Object: _.isObject,
		Function: _.isFunction,
		String: _.isString,
		Number: _.isNumber,
		Array: _.isArray,
		Undefined: _.isUndefined,
		Boolean: _.isBoolean,
	}

	const handleError = (err) => {
		if(webpackGlobal.debug) {
			console.error(err)
		} else {
			throw err
		}
	}

	return validations
		.filter((validation) => {
			// option.type can be string or array, we need to make sure we work
			// with array so we put it inside of new array and _.flatten it
			const types = _.flatten([validation.type])
			// Check every validation
			const regularValidations = types
				.filter((type): type is methodTypeValidation => _.isString(type))
				.map((type) => !method[type](validation.val))
			const customValidations = types
				.filter((type): type is ((val: any) => boolean) => _.isFunction(type))
				.map((validation_fn) => !validation_fn(validation.val))
			return [
				...regularValidations,
				...customValidations
			]
			// Check if every condition pass and equal
			.every((val) => val)

		})
		.map((option) => {
			const types = _.flatten([option.type]).join(' or ')
			const validations = (_.flatten([option.type]).some(_.isFunction)) ? `pass the follow validations '${types}'` : `be '${types}'`
			const err = new Error(`Contract Error: ${option.name} should ${validations}, you pass: ${option.val}`)
			return handleError(err)
		})
}

// This restartable will determine if the module need new instance or not and if so he will manage the instances.
// We have a lot of duplicate code for initialize each module and in order to reduce it we use the function in each module.
function restartable<T extends Initiable<T>>(self: T, config, defaults, props, instance?: T): T {
	// Check if it's first time we initialize the class or we want to restart the instance with new configurations
	if (!instance || config.restart) {
		// We dont want to restart the class for every instance,
		// we want to do it only once.
		delete config.restart
		// Back to unloaded
		self.loaded = false
		return self.init(config, defaults, props)
	}

	// Return every time same instance we saved in the first initialize
	return instance
}

export default {
	get_hash,
	moduleInitializer,
	noop,
	trigger,
	validateDependencies,
	on,
	restartable,
	is_browser,
	get_environment_global_var
}
