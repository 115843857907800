import Socket from './socket/socket'
import Http from './http/http'

import Sdk from '../sdk'
import conf from './communication_config'
import utils from '../utils'
import * as _ from 'lodash'
import BasicModule from '../basic-module'

let instance = null

interface CommunicationConfig {
	api_key?: any
	client_token?: any
	token?: any
	defaults?: any
	fetch_timer?: any
	local?: any
	local_address?: any
	method?: any
	requestType?: 'http' | 'socket'
	access_token?: any
	app_data?: any
	cookie?: any
	hashed_id?: any
	id?: any
	url?: any
	domain?: any
	api_version?: 'v1' | 'v2'
	protocol?: any
	socketDomain?: any
}

/**
 * Communication is a module that manages the entire network of the SDK.
 * Communication is a top level api for the sdk above http module and
 * socket module or any other network module, he menage the traffic for
 * the entire SDK.
*/
class Communication extends BasicModule implements Initiable<Communication> {
	loaded: boolean
	communicationModules: any[]
	config: CommunicationConfig

	http: Http
	socket: Socket

	/**
	 * Construct the module.
	 * @private
	 * @param {CommunicationConfig} [config] - Configurations object.
	 * @returns Module instance.
	*/
	constructor(config?: CommunicationConfig) {
		super()
		// This restartable will determine if the module need new instance or not and if so he will manage the instances.
		const init = utils.restartable<this>(this, config || {}, conf.defaults.communication, conf.configProps, instance)
		return instance = init
	}

	/**
	 * Init the module.
	 * @version 1.0.0
	 * @private
	 * @async
	 * @param {CommunicationConfig} [config] - Configurations object.
	 * @param {Object}[defaults = conf.defaults.communication] - Defaults object.
	 * @param {Array} [props = conf.configProps] - Valid config properties array.
	 * @param {Class} [http = Http] - Http class.
	 * @param {Class} [socket = Socket] - Socket class.
	 * @returns {Promise<Array>} - Child modules instances.
	 */
	init(config: CommunicationConfig, defaults = conf.defaults.communication, props = conf.configProps, sdk = new Sdk, http = Http, socket = Socket) {
		this.communicationModules = [] // Set list of sub modules.

		const getEnvConfig = (config) => {
			const env = (config.local) ? 'local' : 'production'
			const localConfig = Object.assign({}, defaults.env[env])
			if (config.local) {
				const protocol =  config.protocol || 'http:'
				const socketProtocol =  (protocol === 'http:') ? 'ws:' : 'wss:'
				localConfig.domain = `${protocol}//${config.local_address}`
				localConfig.socketDomain = `${socketProtocol}//${config.local_address}`
			}
			return Object.assign({}, config, localConfig)
		}
		// Get env configurations.
		config = getEnvConfig(config)

		// Merge configurations.
		const concatConfig = Object.assign({}, defaults, config)

		this.config = _.pick(concatConfig, props)
		sdk.config = Object.assign({}, sdk.config, this.config)
		// Resolve sub modules.
		utils.moduleInitializer<Http>(this, 'http', Http, this.getConfig())
		this.communicationModules.push('http')

		utils.moduleInitializer<Socket>(this, 'socket', Socket, this.getConfig())
		this.communicationModules.push('socket')

		this.loaded = true

		return this
	}

	/**
	 * Get instance of config object.
	 * @version 1.0.0
	 * @private
	 * @returns {Object} config instance.
	 * @example
	 * captain.communication.getConfig() // Returns safe clone of the module configuration.
	 */
	getConfig() {
		return _.cloneDeep(this.config)
	}

	/**
	 * Set update to config object.
	 * @version 1.0.0
	 * @private
	 * @param {Object} configUpdate - Update for config object.
	 * @returns {Object} Config instance.
	 * @example
	 * captain.communication.setConfig({foo: 'bar'}) // Set values to module configuration and returns safe clone of the updated module configuration.
	 */
	setConfig(configUpdate) {
		// Merge the update to config.
		Object.assign(this.config, configUpdate)
		return this.getConfig()
	}

	/**
	 * Main function for communication, abstract the Http and Socket modules API.
	 * Using configurations 'request' figure what type of request to send and what the request will include.
	 * @version 1.0.0
	 * @async
	 * @public
	 * @param {String} url - Destination url.
	 * @param {Object} [params = {}] - Request parameters.
	 * @param {Object} [config] - Request custom config.
	 * @param {Function} [cb = this.onSuccess] - Success callback.
	 * @returns {Promise<Any>} Server response.
	 * @example
	 * ```
	 * // Example to request of app settings:
	 * const url = `${captain.communication.getConfig().domain}/mechanics/${captain.communication.getConfig().api_version}/app/${captain.config.api_key}` // Url to the API entry
	 * const params = { // The parameters that we want to send
	 * 	lang: captain.config.lang,
	 * 	country: captain.config.country,
	 * 	currency: captain.config.currency
	 * }
	 * const config = {requestType: 'http', method: 'get'} // Request configurations
	 *
	 * return communication.request(url, params, config)
	 * ```
	 */
	request(url, params = {}, config: {requestType?: 'http' | 'socket', method?: string} = {}, cb = this.onSuccess): Promise<any> {
		// Validate the requires to run the method.
		utils.validateDependencies([
			{name: 'url', type: 'String', val: url},
			{name: 'params', type: 'Object', val: params},
			{name: 'config', type: 'Object', val: config},
			{name: 'cb', type: 'Function', val: cb}
		])

		// Figure out the method and the request type of the request.
		const {method, requestType} = this.requestBuilder(config)
		// Set up the request parameters
		const {parameters, headers} = this.setDefaults(params, method, config)
		// Run the request using dynamic configurations and pipe the response throw json
		// parser and callback method or throw errorHandler if there is an error
		const response = this[requestType][method](url, parameters, {...config, headers})

		if(requestType === 'http') {
			return response
				.then(this.jsonParser)
				.then(this.filterBadRequest)
				.then(cb)
				.catch(this.errorHandler)
		}

		return response
	}

	/**
	 * Set up default params and headers to requests base on params and configurations.
	 * @version 1.0.0
	 * @private
	 * @param {Object} [parameters] - Request parameters.
	 * @param {String} method - Request method.
	 * @param {Object} [config] - Request config.
	 * @param {Object} [sdk = new Sdk] - Sdk instance.
	 * @returns {Object} Request ready parameters.
	 */
	setDefaults(parameters: any = {}, method, config: any = {}, sdk = new Sdk) {
		utils.validateDependencies([
			{name: 'parameters', type: 'Object', val: parameters},
			{name: 'method', type: 'String', val: method},
			{name: 'sdk', type: 'Object', val: sdk},
		])

		const headers = {
			// Sending any data that we have on the headers.
			...((!_.isEmpty(sdk.config.player) || sdk.config.user) ? {
				// The current user's id (either anonymous or registered user id).
				...(sdk.config.player.id) ? {user_id: sdk.config.player.id} : {},
				...((sdk.config.user || {}).id) ? {player_app_specific_id: sdk.config.user.id} : {},
				...(sdk.config.player.app_specific_id) ? {player_app_specific_id: sdk.config.player.app_specific_id} : {},
			} : {}),
			...config.headers
		}

		if (method === 'get')
			return {parameters, headers}

		if (sdk.config.client_token) {
			parameters.client_token = sdk.config.client_token
			parameters.access_token = sdk.config.player.access_token
		}
		// Add for every request a default params:
		return {
			headers,
			parameters: {
				// Generate hash id for the request.
				id: `msg${utils.get_hash()}`,
				// The app's API key.
				app: sdk.config.api_key,
				// The current user's id (either anonymous or registered user id).
				user: sdk.config.player.id,
				// The current date, as milliseconds since UNIX epoch.
				date: new Date().getTime(),
				// The SDK the request was sent from.
				sdk: 'js',
				...parameters
			}
		}
	}

	/**
	 * Valuate the proper method and request type we should use for the current request.
	 * @version 1.0.0
	 * @private
	 * @param {Object} [config] - request config.
	 * @throws {CommunicationError} Request type is not exist.
	 * @returns {Object} Request type and method.
	 */
	requestBuilder(config: {requestType?: 'http' | 'socket', method?: string} = {method: null, requestType: null}) {
		utils.validateDependencies([
			{name: 'config', type: 'Object', val: config},
		])

		// Find the method.
		const method = config.method || this.config.method
		// Find the request type.
		const requestType = config.requestType || this.config.requestType
		const request = {method: null, requestType: null}
		// validate the requestType.
		if (requestType && this.communicationModules.includes(requestType)) {
			request.requestType = requestType
		} else {
			throw new Error('Communication Error: It seems that you try to make a request with non-existent or unregistered communication module')
		}

		// validate the method.
		if (method && this[requestType].methods.includes(method))
			request.method = method

		return request
	}

	/**
	 * JSON parser for server responses.
	 * @version 1.0.0
	 * @private
	 * @param {Object} res - Server response.
	 * @throws  {CommunicationError} Can't parse the json.
	 * @returns {Object} Response after parsing.
	 */
	jsonParser(res) {
		utils.validateDependencies([
			{name: 'res', type: 'Object', val: res},
		])

		try {
			return res.json()
		} catch (err) {
			throw new Error(`Communication Error: ${err}`)
		}
	}

	/**
	 * Error handler for requests.
	 * @version 1.0.0
	 * @private
	 * @param {Object} [err = 'Fetching Data Failed'] - Error interface.
	 * @throws  {CommunicationError} Server response error.
	 * @returns {Promise<any>} Promise rejection with an error message.
	 */
	errorHandler(err = 'Fetching data failed') {
		utils.validateDependencies([
			{name: 'err', type: ['Object', 'String'], val: err},
		])

		// Handler for communication errors.
		console.error('Communication Error:', err)
		return Promise.reject(err)
	}

	/**
	 * Default callback take the response and extract the data.
	 * @version 1.0.0
	 * @private
	 * @param {Object}[res] - Server response.
	 * @returns {Any} Server response data.
	 */
	onSuccess(res) {
		utils.validateDependencies([
			{name: 'res', type: 'Object', val: res},
		])
		// Handler for communication success.
		if (res.data)
			return res.data
		return res
	}

	/**
	 * HeartBeat ping the server in order to keep the connection alive.
	 * @version 1.0.0
	 * @private
	 * @param {Object} socketConnection - Socket connection.
	 * @returns {Function} - Cancel function.
	 */
	heartBeat(socketConnection) {
		utils.validateDependencies([
			{name: 'socketConnection', type: 'Object', val: socketConnection},
		])

		const time = 18000
		const ping = (connection) => {
			if(connection.readyState === connection.OPEN)
				connection.send('ping')
		}
		const intervalId = setInterval(() => ping(socketConnection), time)

		return (id = intervalId) => {
			clearInterval(id)
		}
	}

	/**
	 * Filter bad request from returning in the success channel.
	 * @version 1.0.0
	 * @param {Object} response - Server response.
	 * @returns {Object|Promise<Object>} Server response or rejection of server response.
	 */
	filterBadRequest(response) {
		utils.validateDependencies([
			{name: 'response', type: 'Object', val: response},
		])

		if (response.code !== 200)
			return Promise.reject(response)
		return response
	}
}

export default Communication
